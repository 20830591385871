import React, { useContext } from "react";
import SectionHeader from "../../components/Headers/SectionHeader";
import TextRowWithAsset from "../../components/TextRowWithAsset";
import { ToolsDataContext } from "../../context";

const Ekipmanlar = () => {
  const data = useContext(ToolsDataContext);

  return (
    <section id="ekipmanlar">
      <SectionHeader text="Ekipmanlar" />
      {data?.edges.map(({ node }, index) => (
        <TextRowWithAsset
          key={`${node.id}`}
          asset={node?.ekipman_resmi?.childImageSharp.gatsbyImageData}
          heading={node.ekipman_ismi}
          text={node.ekipman_aciklamasi}
          reverse={index % 2 === 0}
          secondParagraph={node?.ekipman_aciklamasi_2}
        />
      ))}
    </section>
  );
};
export default Ekipmanlar;
